import * as $ from 'jquery'
function showOrHideBookmark() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
    $('.bookmark').hide()
  } else {
    $('.bookmark').show()
  }
}

$(document).on('click', '.js-print', function (e) {
  e.preventDefault()
  // saving click on GA
  var href = $(this).attr('href')
  var data = {
    eventCategory: 'share-print',
    eventAction: 'click',
    eventLabel: href
  }
  if (typeof ga !== 'undefined') ga('send', 'event', data)
  window.print()
})

$(document).on('click', '.bookmark', function (e) {
  e.preventDefault()
  var bookmarkURL = window.location.href
  var bookmarkTitle = document.title

  if ('addToHomescreen' in window && addToHomescreen.isCompatible) {
    // Mobile browsers
    addToHomescreen({ autostart: false, startDelay: 0 }).show(true)
  } else if (window.sidebar && window.sidebar.addPanel) {
    // Firefox <=22
    window.sidebar.addPanel(bookmarkTitle, bookmarkURL, '')
  } else if ((window.sidebar && /Firefox/i.test(navigator.userAgent)) || (window.opera && window.print)) {
    // Firefox 23+ and Opera <=14
    $(this)
      .attr({
        href: bookmarkURL,
        title: bookmarkTitle,
        rel: 'sidebar'
      })
      .off(e)
    return true
  } else if (window.external && 'AddFavorite' in window.external) {
    // IE Favorites
    window.external.AddFavorite(bookmarkURL, bookmarkTitle)
  } else {
    // Other browsers (mainly WebKit & Blink - Safari, Chrome, Opera 15+)
    alert('Presiona ' + (/Mac/i.test(navigator.userAgent) ? 'Cmd' : 'Ctrl') + '+D para guardar esta página.')
  }

  // saving click on GA
  var href = $(this).attr('href')
  var data = {
    eventCategory: 'share-bookmark',
    eventAction: 'click',
    eventLabel: href
  }
  if (typeof ga !== 'undefined') ga('send', 'event', data)

  return false
})

$(document).on('click', '.js-share', function (e) {
  e.preventDefault()
  e.stopPropagation()

  if (navigator.share) {
    var title = $(this).data().title
    var contents = ($(this).data().contents || '').slice(0, 80)
    var url = $(this).data().url
    navigator
      .share({
        title: title,
        text: contents,
        url: url || window.location.href
      })
      .then(function () {
        return console.log('Share complete')
      })
      .error(function (error) {
        return console.error('Could not share at this time', error)
      })
  } else {
    $('.social-utils__share-content').slideToggle('fast')
    var icon = $(this).find('.social-utils__icon')
    icon.toggleClass('active')
    icon.next('.social-utils__icon-description').toggleClass('active')
    var target = $(this).data('target')
    $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'false' ? true : false)

    if (target) $(target).toggle()
    else $(this).parents().siblings('.social-utils__share-content').toggle()
  }
})

$(document).on('click', '.js-nation-message-share', function (e) {
  e.preventDefault()
  e.stopPropagation()
  var target = $(this).data('target')
  $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'false' ? true : false)

  if ($(target).css('visibility') === 'hidden') {
    $(target).css('visibility', 'visible')
  } else {
    $(target).css('visibility', 'hidden')
  }
})

$(document).on('turbolinks:load', function () {
  showOrHideBookmark()
  $(window).on('resize', showOrHideBookmark)
  $('.social-utils__share-content').css('display', 'none')
})
