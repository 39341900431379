import * as $ from 'jquery'
$(document).on('turbolinks:load', function () {
  var skipBackwardLink = document.querySelector('#js-skip-backward-twitter-feed')
  if (!skipBackwardLink) {
    return
  }

  var lastSocialMediaLink = $('.social_media_links li').last()[0]
  skipBackwardLink.href = '#' + lastSocialMediaLink.id
})
