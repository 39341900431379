import debounce from 'debounce'

document.addEventListener('turbolinks:load', () => {
  document.querySelector('.js-search-form')?.addEventListener('ajax:beforeSend', event => {
    const [xhr, data] = event.detail
    window.filters_timestamp = new Date().getTime()
    xhr.setRequestHeader('timestamp', window.filters_timestamp)
    const loader = document.querySelector('.js-search-loader')
    loader.classList.remove('hidden')
    const results = document.querySelector('.js-search-results')
    results.innerHTML = ''
  })

  const input = document.querySelectorAll('.js-search-input')

  const submitForm = debounce(el => {
    const form = el.closest('form')
    form.dispatchEvent(new Event('submit', { bubbles: true }))
  }, 500)

  input.forEach(el =>
    el.addEventListener('input', event => {
      submitForm(el)
    })
  )
})
