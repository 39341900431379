import * as $ from 'jquery'
$(document).on('turbolinks:load', function () {
  $('#content_tree').each(function () {
    $.each($('.form-group'), function (k, v) {
      if (k > 0) {
        $(v).hide()
      }
    })

    $('#send_button').hide()

    $.each(['.criteria-form__select', '.criteria-form__radio'], function (k, selector) {
      $(selector).on('change', function () {
        const $this = $(this)
        const criteria_form = $('#form_criteria')
        const form_action = criteria_form.prop('action')

        const next_groups = $this.closest('.form-group').nextAll('.form-group')
        hide_elements(next_groups)

        const serialized = criteria_form.serialize()

        $.ajax({
          method: 'post',
          dataType: 'json',
          data: serialized,
          url: form_action
        }).done(function (res) {
          show_next_field($this, res[2]['condition'])
          if (res[2]['condition'] == 'fin') {
            renderResponse($this, res)
          }
        })
      })
    })
  })

  function renderResponse(sel, res) {
    let $html = ''
    const message_head = res[0]
    const message_foot = res[1]
    res.splice(0, 1)
    const results = res.filter(get_result)
    const requirements = res.filter(get_requirement)
    const notes = res.filter(get_notes)

    $html = '<h5>' + message_head.head + '</h5>'
    $.each(results, function (k, v) {
      $html += '<p>' + v.name + '</p>'
    })
    if (requirements.length > 0) {
      $html += '<ul>'
      $.each(requirements, function (k, v) {
        $html += '<li>' + v.name + '</li>'
      })
      $html += '</ul>'
    }

    if (notes.length > 0) {
      $.each(notes, function (k, v) {
        if (v.name) {
          $html += '<p class="criteria__note">' + v.name + '</p>'
        }
      })
    }
    $('#result_message').html($html)
    $('#result_message').show()
    // $('#button_again').show();
  }

  function show_next_field(sel, next_el) {
    // sel.attr("disabled",true)
    // $("input[name="+sel.attr("name")+"]").attr("disabled",true);
    $('#' + next_el).show()
  }
  function get_result(v) {
    return v.type == 'result'
  }
  function get_requirement(v) {
    return v.type == 'requirement'
  }
  function get_notes(v) {
    return v.type == 'note'
  }
  function hide_elements(next_groups) {
    next_groups.each(function () {
      if ($(this).is(':visible')) {
        $(this).find('select').val('')
        $(this).find('input:radio').prop('checked', false)
        $(this).hide()
      }
    })
    $('#result_message').hide()
  }
})
