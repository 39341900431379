import * as $ from 'jquery'
$(document).on('turbolinks:load', function () {
  $('.toggle-on-clic').each(function () {
    var target = $(this).data('target')
    $(this).on('click', function () {
      $(target).toggle()
    })
  })

  if ($('.clipboard').length > 0) {
    new Clipboard('.btn-clipboard')
  }
})
