import * as $ from 'jquery'
import Awesomplete from 'awesomplete'
import debounce from 'lodash/debounce'

document.addEventListener('turbolinks:load', function (params) {
  $('.search_autocomplete').each(function () {
    var url = $(this).data('url')
    var institutionId = $(this).data('institution-id')
    var awesomplete = new Awesomplete(this, {
      minChars: 2,
      sort: false,
      replace: function (text) {
        this.input.value = text?.replace('Ver más resultados de ', '')
      },
      filter: function () {
        return true
      }
    })
    this.addEventListener('awesomplete-selectcomplete', function (event) {
      window.location.href = event.text.value
    })
    var searchTitle = debounce(function (event) {
      if ((event.keyCode >= 48 && event.keyCode <= 90) || event.keyCode == 229) {
        if (this.value.match(/[A-Za-z0-9]/)) {
          $.ajax({
            url: `${url}?term=${window.encodeURIComponent(this.value)}&institution_id=${institutionId}`,
            type: 'GET',
            dataType: 'json',
            success: function (data) {
              var list = []
              $.each(data, function (key, value) {
                list.push({ label: value.name_with_parent, value: value.path })
              })
              awesomplete.list = list
            }
          })
        }
      }
    }, 200)
    $(this).on('keyup', searchTitle)
  })
})
