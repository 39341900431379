document.addEventListener('turbolinks:load', event => {
  document.querySelectorAll('a').forEach(el => {
    const href = el.getAttribute('href')
    const rel = el.getAttribute('rel')
    const has_general_link = el.classList.contains('general_link')

    if (!href) return

    el.classList.remove('hover:no-underline')
    el.classList.add('underline')

    if (rel == 'prev' || rel == 'next' || has_general_link) {
      el.classList.remove('underline')
    }
  })

  document.querySelectorAll('span').forEach(el => {
    const text = el.innerText
    if (text.includes('Leer más')) {
      el.classList.add('underline')
      el.classList.add('hover:font-bold')
    }
  })
})
