document.addEventListener('turbolinks:load', function (event) {
  const searchInput = document.getElementById('input-search-home-gobpe')
  const searchButton = document.getElementById('btn-search-home-gobpe')

  function updateSearchButtonState(event) {
    const inputLength = event.currentTarget.value.length
    const isDisabled = inputLength <= 0
    searchButton.disabled = isDisabled
  }

  if (searchInput) {
    searchInput.addEventListener('input', updateSearchButtonState)
  }
})
