import * as $ from 'jquery'
import { clickEvent } from './clickEvent'

$(document).on(clickEvent, '.js-see-all', function () {
  var $this = $(this),
    icon = $this.find('svg.inline'),
    childrenList = $(this).closest('.entity').find('.children-list')
  icon.toggleClass('hidden')
  $($this).attr('aria-expanded', function (i, attr) {
    return attr == 'true' ? 'false' : 'true'
  })
  childrenList.slideToggle(300)
})

$(document).on('change', '.js-zone-entities', function () {
  var selectedZone = $(this).val()
  if (selectedZone != '') {
    $('#entities').fadeOut()
    $.ajax({
      url: $(this).data('url'),
      data: {
        zone: selectedZone
      }
    })
  } else {
    $('#entities').html('')
  }
})

document.addEventListener('turbolinks:load', function () {
  if (window.location.pathname.indexOf('estado') == -1) {
    return
  }

  var hash = window.location.hash
  if (!hash) {
    return
  }

  var element = document.getElementById(hash.substr(1))
  if (!element) {
    return
  }

  var seeAllButton = element.querySelector('.js-see-all')
  if (!seeAllButton) {
    return
  }

  seeAllButton.click()
})
