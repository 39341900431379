import * as $ from 'jquery'
$(document).on('turbolinks:load', function () {
  $('.track-ga-click').each(function () {
    const origin = $(this).data('origin')

    // A hack, if you have a better solution, fix it!
    if (origin && origin.endsWith('links-de-interes')) {
      $(this).attr('data-origin', origin + '-' + $(this).text().toLowerCase().split(' ').join('-'))
    }
  })

  $(document).on('click', '.track-ga-click', function () {
    const href = $(this).attr('href')
    const origin = $(this).data('origin')
    const action = $(this).data('action')
    const value = $(this).data('value')
    const isExternal = $(this).attr('target') === '_blank'
    if (isExternal) {
      $(this).attr('rel', 'noreferrer')
    }
    const data = {
      eventCategory: origin,
      eventAction: action || 'click',
      eventLabel: href,
      eventValue: value
    }

    if (typeof ga !== 'undefined') {
      const tracker = ga.getAll()[0]
      if (tracker) tracker.send('event', data)
      else ga('send', 'event', data)
    }

    if (typeof gtag !== 'undefined') {
      gtag('event', data.eventAction, data)
    }

    return true
  })
})
