document.addEventListener('turbolinks:load', () => {
  setupAccordion('accordion-academic', 'accordion-academic-svg', 'section-academic')
  setupAccordion('accordion-work', 'accordion-work-svg', 'section-work')

  if (document.getElementById('biography-see-more')) {
    const seeMore = document.getElementById('biography-see-more')
    const biographyShowhide = document.getElementById('biography-showhide')

    seeMore.addEventListener('click', event => {
      biographyShowhide.classList.toggle('hidden-biography')
      seeMore.lastChild.classList.toggle('transform')
      seeMore.lastChild.classList.toggle('rotate-180')
      const textAction = seeMore.firstChild
      if (textAction.innerText === 'Ver menos') {
        textAction.innerText = 'Ver más'
      } else {
        textAction.innerText = 'Ver menos'
      }
    })
  }
})

const setupAccordion = (iconId, svgId, sectionId) => {
  const icon = document.getElementById(iconId)
  const svg = document.getElementById(svgId)
  if (!icon) return

  icon.addEventListener('click', () => {
    icon.classList.toggle('transform')
    svg.classList.toggle('transform')
    svg.classList.toggle('rotate-180')
    document.getElementById(sectionId).classList.toggle('hidden')
  })
}
