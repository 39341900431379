import * as $ from 'jquery'
import truncate from 'html-truncate'

const getTruncateLength = () => {
  const width = $(window).width()
  switch (true) {
    case width > 1200:
      return 325
    case width > 992:
      return 250
    case width > 768:
      return 200
    default:
      return 100
  }
}

const truncateContent = () =>
  $('.search-text').each(function () {
    const length = getTruncateLength() //$(this).data("truncate-length") || 100
    const link = $(this).html().split('<br>')[0]
    const content = $(this).html().split('<br>')[1]
    const truncatedContent = truncate(content || '', length)
    $(this).html(`${link} <br> ${truncatedContent}`)
  })

document.addEventListener('turbolinks:load', truncateContent)
