import { Controller } from 'stimulus'
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['institutionSelect']

  connect() {
    this.choices = new Choices(this.institutionSelectTarget, {
      placeholder: true,
      placeholderValue: 'Seleccionar',
      searchPlaceholderValue: 'Buscar...',
      shouldSort: false,
      removeItemButton: true,
      searchResultLimit: 10,
      searchEnabled: true,
      noResultsText: 'No se encontraron resultados',
      itemSelectText: 'Seleccionar',
      classNames: {
        containerInner: 'choices__inner__custom',
        input: 'choices__input__custom',
        placeholder: 'opacity-100',
        listItems: 'choices__list--multiple pointer-events-none',
        button: 'choices__button pointer-events-auto'
      }
    })

    this.institutionSelectTarget.addEventListener('search', this.performSearch.bind(this))
  }

  async performSearch(event) {
    const searchValue = event.detail.value.trim()
    if (searchValue.length < 3) {
      return
    }

    const query = new URLSearchParams({ term: searchValue }).toString()

    try {
      const response = await fetch(`/institutions-by-slug?${query}`)
      const json = await response.json()

      const formattedJson = json.map(d => ({
        value: d.id,
        label: d.name
      }))

      this.updateChoices(formattedJson)
    } catch (error) {
      console.error('Error fetching institutions:', error)
    }
  }

  updateChoices(choicesArray) {
    this.choices.clearChoices()
    this.choices.setChoices(choicesArray, 'value', 'label', true)
  }
}
