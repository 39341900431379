import Siema from 'siema'

document.addEventListener('turbolinks:load', () => {
  const siemaSelector = document.querySelector('.siema')
  if (!siemaSelector) return

  const mySiema = new Siema({
    selector: siemaSelector,
    duration: 1000,
    loop: true
  })

  const prevArrow = document.querySelector('.prev-arrow')
  const nextArrow = document.querySelector('.next-arrow')

  prevArrow.addEventListener('click', () => mySiema.prev())
  nextArrow.addEventListener('click', () => mySiema.next())

  // Autoplay
  setInterval(() => mySiema.next(), 5000)
})
