document.addEventListener('turbolinks:load', event => {
  document.querySelectorAll('a').forEach(el => {
    const href = el.getAttribute('href')
    if (!href) return
    const regex = /^(https|http):\/\/www.gob.pe/
    const href_permitted = ['#', '#main', 'javascript:void(0)']
    if (href.startsWith('/') || href.startsWith('#') || regex.test(href) || href_permitted.includes(href)) {
      el.removeAttribute('target')
    } else {
      el.setAttribute('target', '_blank')
    }
  })
})
