/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'
import * as $ from 'jquery'
window.$ = $ // muchos archivos js.erb aún usan jquery
window.Rails = Rails // algunos js.erb usan rails.

document.addEventListener('turbolinks:load', function (event) {
  if (typeof ga === 'undefined') return
  if (typeof URL === 'undefined') return ga('send', 'pageview', event.data.url)
  try {
    const url = new URL(event.data.url)
    return ga('send', 'pageview', url.pathname)
  } catch (error) {
    ga('send', 'pageview', event.data.url)
  }
})

$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  }
})

// común
import '../src/search_home'
import '../src/fix_underline_links'
import '../src/fix_external_links'
import 'src/turbolinks_scroll_solution'
import 'whatwg-fetch'
import '../components/html_truncate'
import '../components/analytics_tracker'
import '../components/campaigns'
import '../components/boxes'
import '../components/slider'
import '../components/siema_slider'
import '../components/select'
import '../components/info'
import '../components/show_more'
import '../components/tabs'
import '../components/date_picker'
import '../components/accordion'
import '../components/check_showhide'
import '../components/institutions/navigator'
import '../components/institutions/chat_with_us'
import '../components/page/calendar'
import '../components/subscriptions/ubigeo'
import '../components/subscriptions/showhide_section'
import '../src/choices.js'
import '../src/sharect.js'
import 'controllers'
import './application_transversal_page'
import './application_link_section'
import '../pages/citizen_content_filters'
import '../components/institutions/nav'
import './application_days_calculator'
import '../components/see_more_less'

// estilos
import '../styles/application.scss'
import '../styles/application_base.scss'

// Se importan todos los módulos dentro de front
function importAll(r) {
  r.keys().forEach(r)
}

importAll(require.context('../src/front', true, /\.js$/))

Rails.start()
Turbolinks.start()
