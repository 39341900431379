//best slider ever: https://pawelgrzybek.github.io/siema/

import Siema from 'siema'

document.addEventListener('turbolinks:load', () => {
  for (let el of document.querySelectorAll('.carrousel')) {
    const selector = el.getAttribute('data-selector')
    const num = el.querySelectorAll('.navigation img')

    let siema = new Siema({
      selector: selector || '.body',
      onChange: () => updateTabIndex(el, siema.currentSlide)
    })

    if (num.length < 1) {
      siema.destroy()
    }

    for (let thumb of num) {
      num[0].parentNode.classList.add('active')
      let index = thumb.getAttribute('data-index')

      thumb.addEventListener('click', () => {
        removeActive(num)
        siema.goTo(index)
        thumb.parentNode.classList.add('active')
      })
    }
  }
})

function removeActive(arr) {
  for (let elem of arr) {
    if (elem.parentNode.classList.contains('active')) {
      elem.parentNode.classList.remove('active')
    }
  }
}

function updateTabIndex(carrousel, index) {
  const images = carrousel.querySelectorAll('.body img')
  removeTabIndex(images)
  images[index]?.setAttribute('tabindex', '0')
}

function removeTabIndex(arr) {
  arr.forEach(elem => elem?.removeAttribute('tabindex'))
}
