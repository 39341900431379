window.setupDaysCalculator = function () {
  const daysCalculatorForm = document.getElementById('days_calculator_form')
  const calendarRadio = document.getElementById('js-calendar-radio')
  const businessRadio = document.getElementById('js-business-radio')
  const calendarCard = document.getElementById('js-calendar-card')
  const businessCard = document.getElementById('js-business-card')
  const inputs = document.querySelector('.js-inputs')
  const businessNote = document.querySelector('.js-business-note')
  const countInput = document.getElementById('js-count-input')
  const textInput = document.getElementById('js-text-at-input')
  const dateInput = document.getElementById('js-start-at-input')
  const calculateButton = document.getElementById('js-calculate-button')

  const toggleInputs = () => {
    const isBusiness = businessRadio.checked
    const isCalendar = calendarRadio.checked

    if (isBusiness || isCalendar) removeClasses(inputs, 'hidden')

    toggleClasses(businessNote, ['hidden'], isCalendar)
    toggleClasses(calendarCard, ['border-blue-500', 'border-3', 'shadow-lg'], isCalendar)
    toggleClasses(businessCard, ['border-blue-500', 'border-3', 'shadow-lg'], isBusiness)

    const typeOfDays = isBusiness ? 'hábiles' : 'calendario'
    textInput.textContent = `días ${typeOfDays} a partir del:`
  }

  const toggleButtonState = () => {
    const isInputValid = countInput.value > 0 && countInput.value < 700 && dateInput.value

    calculateButton.disabled = !isInputValid
    toggleClasses(calculateButton, ['bg-gray-400', 'text-gray-600', 'cursor-not-allowed'], !isInputValid)
    toggleClasses(calculateButton, ['bg-blue-700', 'text-white', 'cursor-pointer'], isInputValid)
  }

  const toggleClasses = (element, classes, condition) => {
    if (condition) {
      element.classList.add(...classes)
    } else {
      element.classList.remove(...classes)
    }
  }

  const removeClasses = (element, ...classes) => element.classList.remove(...classes)

  if (daysCalculatorForm) {
    calendarRadio.addEventListener('change', toggleInputs)
    businessRadio.addEventListener('change', toggleInputs)
    countInput.addEventListener('input', toggleButtonState)
    dateInput.addEventListener('input', toggleButtonState)

    toggleButtonState()
    toggleInputs()
  }
}

document.addEventListener('turbolinks:load', window.setupDaysCalculator)
