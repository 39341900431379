document.addEventListener('turbolinks:load', event => {
  document.querySelectorAll('.js-subscription').forEach(el => {
    const subscriptionButton = el.querySelector('.js-button-subscription')
    const cerrarLink = el.querySelector('.js-cerrar-subscription')
    const topicSubscription = el.querySelector('.js-form-subscription')

    subscriptionButton.addEventListener('click', () => {
      if (topicSubscription.classList.contains('hidden')) {
        topicSubscription.classList.remove('hidden')
        subscriptionButton.classList.add('hidden')
        cerrarLink.classList.remove('hidden')
      }
    })

    cerrarLink.addEventListener('click', () => {
      if (!topicSubscription.classList.contains('hidden')) {
        topicSubscription.classList.add('hidden')
        subscriptionButton.classList.remove('hidden')
        cerrarLink.classList.add('hidden')
      }
    })
  })
})
