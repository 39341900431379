document.addEventListener('turbolinks:load', event => {
  const ubigeoActions = el => {
    const regionSelect = el.querySelector('.js-region')
    const ajax_url = '/ubigeo2s.json'
    const provinceSelect = el.querySelector('.js-province')
    const ubigeoSelect = el.querySelector('.js-ubigeo')

    regionSelect.addEventListener('change', event => {
      const idRegion = event.target.value
      if (idRegion === '') {
        provinceSelect.innerHTML = '<option value=""> Provincia 1 </option>'
        ubigeoSelect.innerHTML = '<option value=""> Distrito </option>'
        return
      }
      // http://localhost:3000/ubigeo2s.json?region_code=10000
      const url = `${ajax_url}?region_code=${idRegion}`
      provinceSelect.innerHTML = '<option value=""> Cargando Provincias ...  </option>'
      ubigeoSelect.innerHTML = '<option value=""> Distrito </option>'

      fetch(url)
        .then(resp => resp.json())
        .then(data => {
          provinceSelect.innerHTML = '<option value="">Provincia </option>'
          ubigeoSelect.innerHTML = '<option value=""> Distrito </option>'

          refreshDropdownValues(data, provinceSelect)
        })
        .catch(function (error) {
          console.log(error)
        })
    })

    provinceSelect.addEventListener('change', event => {
      const idProvince = event.target.value
      if (idProvince === '') {
        ubigeoSelect.innerHTML = '<option value=""> Distrito </option>'
        return
      }

      // http://localhost:3000/ubigeo2s.json?province_code=10100
      const url = `${ajax_url}?province_code=${idProvince}`
      ubigeoSelect.innerHTML = '<option value=""> Cargando Distritos ... </option>'

      fetch(url)
        .then(resp => resp.json())
        .then(data => {
          ubigeoSelect.innerHTML = '<option value=""> Distrito </option>'

          refreshDropdownValues(data, ubigeoSelect)
        })
        .catch(function (error) {
          console.log(error)
        })
    })

    const refreshDropdownValues = (data, target) => {
      target.disabled = false
      for (var i = 0; i < data.length; i++) {
        var opt = data[i]
        target.innerHTML += '<option value="' + opt.id + '">' + opt.name + '</option>'
      }
    }
  }

  document.querySelectorAll('.js-ubigeo-list').forEach(el => {
    ubigeoActions(el)

    window.ubigeoActions = ubigeoActions
  })
})
