import * as $ from 'jquery'
$(document).on('turbolinks:load', function () {
  if (!$('.pages-show').length) return
  function setVisibleTab() {
    var tabId = window.location.hash
    if (!tabId) {
      return
    }

    $('.tabs-content').hide()
    $('.tabs .link').removeClass('page__modality--active')
    var tab = $('a[href="' + tabId + '"]')
    $(tab).addClass('page__modality--active')
    $(tab).next().show()
    $(tabId).show()
  }

  $('.tabs-content.keep_hide, .modality-title-no-js').css('display', 'none')

  if ($('.tabs .link')) {
    setVisibleTab()
    window.addEventListener('hashchange', setVisibleTab)
  }

  $('.tabs .link').on('click', function (event) {
    event.preventDefault()
    window.location.hash = this.hash
    $('.tabs .link').removeClass('page__modality--active')
    $(this).addClass('page__modality--active')
    $(this).next().show()
    var tabId = $(this).attr('href')
    $('.tabs-content').hide()
    $(tabId).show()
  })
})
