import * as $ from 'jquery'
import { clickEvent } from './clickEvent'
$(document).on('keyup', '.search', function () {
  var form = $(this).closest('form')
  if ($(this).val().length > 0) {
    $(form).find('.icon-search-s').css('color', '#2f2f2f')
  } else {
    $(form).find('.icon-search-s').css('color', '#72787f')
  }
})

$(document).on(clickEvent, '.js-close-notification', function (event) {
  $('.js-notification').hide('fast')
})

$(document).on('click', '.menu-item', function () {
  $('.menu-items').slideToggle('fast')
})

$(document).on('click', '.menu-items a', function () {
  $('.menu-items').hide()
})

$(document).on(clickEvent, '.js-footer-links-toggle', function () {
  if (window.matchMedia('(min-width: 768px)').matches) {
    return
  }
  var footerLinks = $(this).parent().find('.js-footer-links')
  var iconPlus = $(this).find('.arrow-plus')

  footerLinks.toggle()
  footerLinks.find('ul').toggleClass('visible')
  iconPlus.toggle()
})

$(document).on(clickEvent, '.submenu .active-page', function (event) {
  $('.submenu .submenu__list').toggle()
  $('.submenu .right-submenu').toggle()
  var icon = $('.submenu .active-page').find('.arrow')
  icon.toggleClass('icon-add-s')
  icon.toggleClass('icon-minus')
  var pageNav = $('.submenu .active-page').find('.active-page-nav')
  pageNav.toggleClass('expanded-page-nav')
  var lastChildNav = $('.submenu-content .nav-options').find('.nav').last()
  lastChildNav.toggleClass('last-nav-options')
})

$(document).on(clickEvent, '.js-filter-toggle', function (event) {
  $(this).next('.filter__type').toggleClass('filter__type--visible')
  var icon = $(this).find('.icon')
  icon.toggleClass('icon-add-s')
  icon.toggleClass('icon-minus')
})

$(document).on('change', '#regions_select', function (event, data) {
  let region_code = $('#regions_select').children('option:selected').val()
  const institutionIdInput = document.getElementById('institution-id')
  const branchChoice = document.getElementById('branch_offices_select').choicesDocuments
  branchChoice.clearStore()
  $.getJSON(
    '/institucion/' + institutionIdInput.value + '/sedes/ubigeo?region_code=' + region_code,
    function (branchOffices) {
      if (branchOffices.length) {
        branchChoice.setChoices(branchOffices, 'id', 'name', true)
        branchChoice.setChoiceByValue(branchOffices[0].id)
        $('#branch_offices_select').change()
      }
    }
  )
})

$(document).on('change', '.js-branch_offices_select', function (event, data) {
  const x = $(this).children('option:selected').val()
  if (!x) return
  const note = document.querySelector('.js-institution-closest-branch-office-note')
  if (note) {
    note.textContent = ''
  }

  const closestBranchOfficeLink = document.querySelector('.js-institution-closest-branch-office-link')
  if (closestBranchOfficeLink && !data) {
    closestBranchOfficeLink.classList.remove('hidden')
  }

  const segment = this.dataset.segment !== undefined ? `?segment=${this.dataset.segment}` : ''

  $.ajax({
    url: `/institutions/getaddress/${x}${segment}`,
    type: 'GET'
  })
})

function menuAssignNumber(menu) {
  var totalNavs = $(menu).length
  var columns = 2
  var maxNavs = 4

  var navs = $('.submenu-content .nav').clone()

  $(menu).each(function (index) {
    var element = index + 1
    var nav = $(this)

    if (totalNavs >= maxNavs) {
      var navs = Math.ceil(totalNavs / columns)

      if (navs >= element) {
        $('.left-submenu').append(nav)
      } else {
        $('.right-submenu').attr('start', navs + 1)
        $('.right-submenu').append(nav)
      }
    }

    if (totalNavs == 3) {
      $('ol.left-submenu').addClass('col-md-12')
      $('ol.left-submenu').removeClass('col-md-6')
    }
  })
}

function isNumber(obj) {
  return !isNaN(parseFloat(obj))
}

const steps = () => {
  let contents = document.querySelectorAll('.content h6')
  contents.forEach(element => {
    let getTexSteps = element.textContent
    if (getTexSteps.indexOf('.') !== -1) {
      let splitStep = getTexSteps.split('.')
      if (splitStep.length === 2) {
        let isNumber = !isNaN(splitStep[0])
        if (isNumber) {
          let createEl = document.createElement('h3')
          element.appendChild(createEl).innerHTML =
            '<span class="step-number">' +
            splitStep[0] +
            '</span>' +
            '<span class="step-title">' +
            splitStep[1] +
            '</span>'
          element.parentNode.replaceChild(createEl, element)
        }
      }
    }
  })
}

function submenuScroll() {
  $('.submenu-content .nav.active').on('click', function (event) {
    event.preventDefault()
    var scrollTo = $('#scroll')
    $('html, body').animate(
      {
        scrollTop: $(scrollTo).offset().top - 75
      },
      500
    )
  })
}

function templateMenu(title, subtitle) {
  var html =
    '<span class="hidden-xs-down">' +
    title +
    ' / </span>' +
    '<div class="selected-result">' +
    subtitle +
    '<i class="directions arrow flecha_abajo_negro"></i>'
  return html
}

function _classCallCheck(t, i) {
  if (!(t instanceof i)) throw new TypeError('Cannot call a class as a function')
}
var Sticky = (function () {
  function t() {
    var i = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : '',
      e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {}
    _classCallCheck(this, t),
      (this.selector = i),
      (this.elements = []),
      (this.version = '1.2.0'),
      (this.vp = this.getViewportSize()),
      (this.body = document.querySelector('body')),
      (this.options = {
        wrap: e.wrap || !1,
        marginTop: e.marginTop || 0,
        stickyFor: e.stickyFor || 0,
        stickyClass: e.stickyClass || null,
        stickyContainer: e.stickyContainer || 'body'
      }),
      (this.updateScrollTopPosition = this.updateScrollTopPosition.bind(this)),
      this.updateScrollTopPosition(),
      window.addEventListener('load', this.updateScrollTopPosition),
      window.addEventListener('scroll', this.updateScrollTopPosition),
      this.run()
  }
  return (
    (t.prototype.run = function () {
      var t = this,
        i = setInterval(function () {
          if ('complete' === document.readyState) {
            clearInterval(i)
            var e = document.querySelectorAll(t.selector)
            t.forEach(e, function (i) {
              return t.renderElement(i)
            })
          }
        }, 10)
    }),
    (t.prototype.renderElement = function (t) {
      var i = this
      ;(t.sticky = {}),
        (t.sticky.active = !1),
        (t.sticky.marginTop = parseInt(t.getAttribute('data-margin-top')) || this.options.marginTop),
        (t.sticky.stickyFor = parseInt(t.getAttribute('data-sticky-for')) || this.options.stickyFor),
        (t.sticky.stickyClass = t.getAttribute('data-sticky-class') || this.options.stickyClass),
        (t.sticky.wrap = !!t.hasAttribute('data-sticky-wrap') || this.options.wrap),
        (t.sticky.stickyContainer = this.options.stickyContainer),
        (t.sticky.container = this.getStickyContainer(t)),
        (t.sticky.container.rect = this.getRectangle(t.sticky.container)),
        (t.sticky.rect = this.getRectangle(t)),
        'img' === t.tagName.toLowerCase() &&
          (t.onload = function () {
            return (t.sticky.rect = i.getRectangle(t))
          }),
        t.sticky.wrap && this.wrapElement(t),
        this.activate(t)
    }),
    (t.prototype.wrapElement = function (t) {
      t.insertAdjacentHTML('beforebegin', '<span></span>'), t.previousSibling.appendChild(t)
    }),
    (t.prototype.activate = function (t) {
      t.sticky.rect.top + t.sticky.rect.height < t.sticky.container.rect.top + t.sticky.container.rect.height &&
        t.sticky.stickyFor < this.vp.width &&
        !t.sticky.active &&
        (t.sticky.active = !0),
        this.elements.indexOf(t) < 0 && this.elements.push(t),
        t.sticky.resizeEvent || (this.initResizeEvents(t), (t.sticky.resizeEvent = !0)),
        t.sticky.scrollEvent || (this.initScrollEvents(t), (t.sticky.scrollEvent = !0)),
        this.setPosition(t)
    }),
    (t.prototype.initResizeEvents = function (t) {
      var i = this
      ;(t.sticky.resizeListener = function () {
        return i.onResizeEvents(t)
      }),
        window.addEventListener('resize', t.sticky.resizeListener)
    }),
    (t.prototype.destroyResizeEvents = function (t) {
      window.removeEventListener('resize', t.sticky.resizeListener)
    }),
    (t.prototype.onResizeEvents = function (t) {
      ;(this.vp = this.getViewportSize()),
        (t.sticky.rect = this.getRectangle(t)),
        (t.sticky.container.rect = this.getRectangle(t.sticky.container)),
        t.sticky.rect.top + t.sticky.rect.height < t.sticky.container.rect.top + t.sticky.container.rect.height &&
        t.sticky.stickyFor < this.vp.width &&
        !t.sticky.active
          ? (t.sticky.active = !0)
          : (t.sticky.rect.top + t.sticky.rect.height >= t.sticky.container.rect.top + t.sticky.container.rect.height ||
              (t.sticky.stickyFor >= this.vp.width && t.sticky.active)) &&
            (t.sticky.active = !1),
        this.setPosition(t)
    }),
    (t.prototype.initScrollEvents = function (t) {
      var i = this
      ;(t.sticky.scrollListener = function () {
        return i.onScrollEvents(t)
      }),
        window.addEventListener('scroll', t.sticky.scrollListener)
    }),
    (t.prototype.destroyScrollEvents = function (t) {
      window.removeEventListener('scroll', t.sticky.scrollListener)
    }),
    (t.prototype.onScrollEvents = function (t) {
      t.sticky.active && this.setPosition(t)
    }),
    (t.prototype.setPosition = function (t) {
      this.css(t, { position: '', width: '', top: '', left: '' }),
        this.vp.height < t.sticky.rect.height ||
          !t.sticky.active ||
          (t.sticky.rect.width || (t.sticky.rect = this.getRectangle(t)),
          t.sticky.wrap &&
            this.css(t.parentNode, {
              display: 'block',
              width: t.sticky.rect.width + 'px',
              height: t.sticky.rect.height + 'px'
            }),
          0 === t.sticky.rect.top && t.sticky.container === this.body
            ? this.css(t, {
                position: 'fixed',
                top: t.sticky.rect.top + 'px',
                left: t.sticky.rect.left + 'px',
                width: t.sticky.rect.width + 'px'
              })
            : this.scrollTop > t.sticky.rect.top - t.sticky.marginTop
              ? (this.css(t, {
                  position: 'fixed',
                  width: t.sticky.rect.width + 'px',
                  left: t.sticky.rect.left + 'px'
                }),
                this.scrollTop + t.sticky.rect.height + t.sticky.marginTop >
                t.sticky.container.rect.top + t.sticky.container.offsetHeight
                  ? (t.sticky.stickyClass && t.classList.remove(t.sticky.stickyClass),
                    this.css(t, {
                      top:
                        t.sticky.container.rect.top +
                        t.sticky.container.offsetHeight -
                        (this.scrollTop + t.sticky.rect.height) +
                        'px'
                    }))
                  : (t.sticky.stickyClass && t.classList.add(t.sticky.stickyClass),
                    this.css(t, { top: t.sticky.marginTop + 'px' })))
              : (t.sticky.stickyClass && t.classList.remove(t.sticky.stickyClass),
                this.css(t, { position: '', width: '', top: '', left: '' }),
                t.sticky.wrap && this.css(t.parentNode, { display: '', width: '', height: '' })))
    }),
    (t.prototype.update = function () {
      var t = this
      this.forEach(this.elements, function (i) {
        ;(i.sticky.rect = t.getRectangle(i)),
          (i.sticky.container.rect = t.getRectangle(i.sticky.container)),
          t.activate(i),
          t.setPosition(i)
      })
    }),
    (t.prototype.destroy = function () {
      var t = this
      this.forEach(this.elements, function (i) {
        t.destroyResizeEvents(i), t.destroyScrollEvents(i), delete i.sticky
      })
    }),
    (t.prototype.getStickyContainer = function (t) {
      for (
        var i = t.parentNode;
        !i.hasAttribute('data-sticky-container') &&
        !i.parentNode.querySelector(t.sticky.stickyContainer) &&
        i !== this.body;

      )
        i = i.parentNode
      return i
    }),
    (t.prototype.getRectangle = function (t) {
      this.css(t, { position: '', width: '', top: '', left: '' })
      var i = Math.max(t.offsetWidth, t.clientWidth, t.scrollWidth),
        e = Math.max(t.offsetHeight, t.clientHeight, t.scrollHeight),
        s = 0,
        o = 0
      do (s += t.offsetTop || 0), (o += t.offsetLeft || 0), (t = t.offsetParent)
      while (t)
      return { top: s, left: o, width: i, height: e }
    }),
    (t.prototype.getViewportSize = function () {
      return {
        width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
        height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      }
    }),
    (t.prototype.updateScrollTopPosition = function () {
      this.scrollTop = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0) || 0
    }),
    (t.prototype.forEach = function (t, i) {
      for (var e = 0, s = t.length; e < s; e++) i(t[e])
    }),
    (t.prototype.css = function (t, i) {
      for (var e in i) i.hasOwnProperty(e) && (t.style[e] = i[e])
    }),
    t
  )
})()
// !(function(t, i) {
//   'undefined' != typeof exports
//     ? (module.exports = i)
//     : 'function' == typeof define && define.amd
//     ? define([], i)
//     : (t.Sticky = i)
// })(this, Sticky)

document.addEventListener('turbolinks:load', () => {
  menuAssignNumber('.submenu-content .nav')
  steps()
})
