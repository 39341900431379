import BadgerAccordion from 'badger-accordion'
document.addEventListener('turbolinks:load', () => {
  const accordions = document.querySelectorAll('.js-badger-accordion')
  Array.from(accordions).forEach(accordion => {
    const ba = new BadgerAccordion(accordion, {
      openMultiplePanels: true
    })
    ba.calculateAllPanelsHeight()
  })
  const accordionButtons = document.querySelectorAll('.js-accordion-button')

  Array.from(accordionButtons).forEach(accordionButton => {
    accordionButton.addEventListener(
      'click',
      function (e) {
        // Arrow interaction
        const accordionFather = this.closest('.js-badger-accordion')
        const arrowDown = this.querySelector('.js-accordion-arrow-down')
        const arrowUp = this.querySelector('.js-accordion-arrow-up')

        accordionFather.querySelectorAll('.js-accordion-arrow-up').forEach(el => {
          if (el != arrowUp) el.classList.add('hidden')
        })
        accordionFather.querySelectorAll('.js-accordion-arrow-down').forEach(el => {
          if (el != arrowDown) el.classList.remove('hidden')
        })
        arrowDown.classList.toggle('hidden')
        arrowUp.classList.toggle('hidden')

        // lines interaction

        accordionFather.querySelectorAll('.js-badger-accordion-header').forEach(el => {
          if (el != this) el.classList.add('js-badger-accordion-header__default')
        })

        this.classList.toggle('js-badger-accordion-header__default')

        const accordionPanel = this.closest('dt').nextSibling

        accordionFather.querySelectorAll('.js-badger-accordion-panel').forEach(el => {
          if (el != accordionPanel) el.classList.remove('js-badger-accordion-panel__selected')
        })

        accordionPanel.classList.toggle('js-badger-accordion-panel__selected')
      },
      false
    )
  })
})
