document.addEventListener('turbolinks:load', event => {
  const showhideCheckActions = el => {
    const elementCheck = el.querySelector('.js-check')
    const element = el.querySelector('.js-element-validation')
    const input = el.querySelector('.js-input-validation')

    elementCheck.addEventListener('click', () => {
      if (elementCheck.checked == true) {
        element.classList.remove('hidden')
      } else {
        element.classList.add('hidden')
        input.value = null
      }
    })
  }

  document.querySelectorAll('.js-showhide-check').forEach(el => {
    showhideCheckActions(el)
    window.showhideCheckActions = showhideCheckActions
  })
})
