function initAccordion(accordion) {
  const button = accordion.querySelector('button')
  const content = accordion.querySelector('.js-accordion-card-content')
  const iconSvg = accordion.querySelector('svg.inline')
  const toggleText = accordion.querySelector('.toggle-text')

  button.addEventListener('click', () => {
    content.classList.toggle('hidden')
    iconSvg.classList.toggle('transform')
    iconSvg.classList.toggle('rotate-180')
    toggleText.textContent = content.classList.contains('hidden') ? 'Ver documentos por mes' : 'Ocultar meses'
  })
}

document.addEventListener('turbolinks:load', () => {
  const accordions = document.querySelectorAll('.js-accordion-card')
  accordions.forEach(accordion => initAccordion(accordion))

  const searchResultsDiv = document.querySelector('.js-search-results')
  if (searchResultsDiv) {
    const observer = new MutationObserver(() => {
      const newAccordions = document.querySelectorAll('.js-accordion-card:not(.accordion)')
      newAccordions.forEach(accordion => {
        initAccordion(accordion)
        accordion.classList.add('accordion')
      })
    })

    observer.observe(searchResultsDiv, { childList: true })
  }
})
