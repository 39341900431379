document.addEventListener('turbolinks:load', function () {
  document.querySelectorAll('.js-branch_offices_select').forEach(branchSelector => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const sede = urlParams.get('sede')
    if (!sede) return
    const branchChoice = branchSelector.choicesDocuments
    const sedes = branchChoice.config.choices
    const sedeInfo = sedes.find(el => el.value === `${sede}`)
    if (typeof sedeInfo === 'object') {
      branchChoice.setChoiceByValue(sedeInfo.value)
      $(branchSelector).change()
    }
  })
})
