import * as $ from 'jquery'
$(document).on('turbolinks:load', function () {
  $('#ubigeo').each(function () {
    function urlify(text) {
      const urlRegex = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/g
      return text.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank" rel="noreferrer">' + url + '</a>'
      })
    }

    function renderCombo(data, target, label) {
      let $html = '<option value="">' + label + '</option>'
      $.each(data, function (k, v) {
        $html += '<option value="' + v.id + '">' + v.label + '</option> '
      })
      target.html($html)
    }

    function renderResult(form) {
      $.ajax({
        method: 'post',
        dataType: 'json',
        data: form.serialize(),
        url: form.prop('action')
      }).done(function (res) {
        let $html = ''
        if (Array.isArray(res) && (res[0].kind == 'tambos' || res[0].kind == 'tambos_yanapay')) {
          if (res.length == 1) {
            const content = JSON.parse(res[0].content)
            $html +=
              '<div>Acércate al <strong>tambo ' +
              content.tambo +
              '</strong>, en el Centro Poblado ' +
              content.centro_poblado +
              ' (' +
              content.distrito +
              ')</div>'
          } else {
            $html += '<span>Acércate a uno de los siguientes tambos:</span>'
            $html += '<ul>'
            $.each(res, function (i, row) {
              const content = JSON.parse(row.content)
              $html +=
                '<li>' +
                content.tambo +
                ', en el Centro Poblado ' +
                content.centro_poblado +
                ' (' +
                content.distrito +
                ')</li>'
            })
            $html += '</ul>'
          }
        } else if (Array.isArray(res)) {
          $html += '<ul class="no-list">'
          res.forEach(rawItem => {
            const item = JSON.parse(rawItem.content)
            $html += `<li>
              <b>${item[0]}</b>
              <p>${item[1]}</p>
              <p class="-mt-5">${item[2]}</p>
            </li>`
          })
          $html += '</ul>'
        } else {
          $html += '<ul class="no-list">'
          $.each(res, function (k, v) {
            $html += '<li><b>' + k + ': </b>' + urlify(v) + '</li>'
          })
          $html += '</ul>'
        }
        $('#result_ubigeo').html($html)
      })
    }

    $('#department').on('change', function () {
      var $this = $(this)
      $.ajax({
        method: 'post',
        dataType: 'json',
        data: { department: $this.val() },
        url: $this.data('url')
      }).done(function (res) {
        const province = $('#province')
        const label_next = 'Provincia'
        renderCombo(res, province, label_next)
        renderCombo([], $('#district'), 'Distrito')
      })
    })
    $('#province').on('change', function () {
      var $this = $(this)
      $.ajax({
        method: 'post',
        dataType: 'json',
        data: { province: $this.val() },
        url: $this.data('url')
      }).done(function (res) {
        const district = $('#district')
        const label_next = 'Distrito'
        renderCombo(res, district, label_next)
      })

      renderResult($('#form_ubigeo_data'))
    })

    $('#district').on('change', function () {
      renderResult($('#form_ubigeo_data'))
    })
  })
})
