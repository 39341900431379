const SCROLL_POSITION_TRANSVERSAL_PAGE = 'scroll-position-transversal-page'

document.addEventListener('turbolinks:load', function () {
  const InputTransversalMenu = document.querySelector('.js-transversal-menu')
  if (InputTransversalMenu) {
    const scrollPosition = JSON.parse(sessionStorage.getItem(SCROLL_POSITION_TRANSVERSAL_PAGE))
    if (scrollPosition) {
      scrollTo(scrollPosition.scrollX, scrollPosition.scrollY)
      sessionStorage.removeItem(SCROLL_POSITION_TRANSVERSAL_PAGE)
    }
    InputTransversalMenu.addEventListener('change', event => {
      Turbolinks.visit(`${window.location.pathname}?child=${event.target.value}`)
      sessionStorage.setItem(
        SCROLL_POSITION_TRANSVERSAL_PAGE,
        JSON.stringify({ scrollX: window.scrollX, scrollY: window.scrollY })
      )
    })
  }
})
