//best slider ever: https://pawelgrzybek.github.io/siema/

import Siema from 'siema'

document.addEventListener('turbolinks:load', () => {
  for (let navigationEl of document.querySelectorAll('.js-campaigns-carrousel-navigation')) {
    const navigationCarousel = new Siema({
      selector: navigationEl,
      perPage: 5
    })

    const el = document.querySelector('.js-campaigns-carrousel')
    const navigationImages = document.querySelectorAll('.js-campaigns-carrousel-navigation img')

    let siema = new Siema({
      selector: el,
      onChange: function () {
        navigationCarousel.goTo(this.currentSlide)
        removeActive(navigationImages)
        navigationImages[this.currentSlide].parentNode.classList.add('active')
      }
    })

    if (navigationImages.length < 1) {
      siema.destroy()
    }

    for (let thumb of navigationImages) {
      navigationImages[0].parentNode.classList.add('active')
      let index = thumb.getAttribute('data-index')

      thumb.addEventListener('click', () => {
        removeActive(navigationImages)
        siema.goTo(index)
        thumb.parentNode.classList.add('active')
      })
    }

    document.querySelector('.js-campaigns-arrow-left').addEventListener('click', () => {
      siema.prev()
    })
    document.querySelector('.js-campaigns-arrow-right').addEventListener('click', () => {
      siema.next()
    })
  }

  const seeMore = document.querySelector('.js-campaigns-feed-see-more')
  if (seeMore) {
    seeMore.addEventListener('click', () => {
      const feeds = document.querySelector(' .js-campaigns-feed-extras')
      if (feeds.classList.contains('hidden')) {
        feeds.classList.remove('hidden')
        seeMore.querySelector('span.see-more').classList.add('hidden')
        seeMore.querySelector('span.see-less').classList.remove('hidden')
        seeMore.querySelector('.js-campaigns-feeds-up').classList.remove('hidden')
        seeMore.querySelector('.js-campaigns-feeds-down').classList.add('hidden')
      } else {
        feeds.classList.add('hidden')
        seeMore.querySelector('span.see-more').classList.remove('hidden')
        seeMore.querySelector('span.see-less').classList.add('hidden')
        seeMore.querySelector('.js-campaigns-feeds-up').classList.add('hidden')
        seeMore.querySelector('.js-campaigns-feeds-down').classList.remove('hidden')
      }
    })
  }

  const seeMoreDocuments = document.querySelector('.js-campaigns-documents-see-more')
  if (seeMoreDocuments) {
    seeMoreDocuments.addEventListener('click', () => {
      const documents = document.querySelector(' .js-campaigns-documents-extras')
      if (documents.classList.contains('hidden')) {
        documents.classList.remove('hidden')
        seeMoreDocuments.querySelector('span').textContent = 'Ver menos'
        seeMoreDocuments.querySelector('.js-campaigns-documents-up').classList.remove('hidden')
        seeMoreDocuments.querySelector('.js-campaigns-documents-down').classList.add('hidden')
      } else {
        documents.classList.add('hidden')
        seeMoreDocuments.querySelector('span').textContent = 'Ver más'
        seeMoreDocuments.querySelector('.js-campaigns-documents-up').classList.add('hidden')
        seeMoreDocuments.querySelector('.js-campaigns-documents-down').classList.remove('hidden')
      }
    })
  }
})

function removeActive(arr) {
  for (let elem of arr) {
    if (elem.parentNode.classList.contains('active')) {
      elem.parentNode.classList.remove('active')
    }
  }
}
