import { clickEvent } from '../helpers'
import 'url-search-params-polyfill'
import * as $ from 'jquery'
const urlParams = new URLSearchParams(window.location.search)

$(document).on('turbolinks:load', function () {
  // no cliqueable: p, ul, li
  // cliqueable: el resto (h2, icono)

  $('.box-collapsable p').on(clickEvent(), function () {
    $(this).closest('.box-collapsable').toggleClass('box-collapsable--show')
  })

  $('.box-collapsable ul').on(clickEvent(), function () {
    $(this).closest('.box-collapsable').toggleClass('box-collapsable--show')
  })

  $('.box-collapsable').on(clickEvent(), function () {
    $(this).closest('.box-collapsable').toggleClass('box-collapsable--show')
  })

  if (urlParams.get('colapsable_abierto') != null)
    $('.box-collapsable').eq(urlParams.get('colapsable_abierto')).addClass('box-collapsable--show')
})
