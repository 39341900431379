document.addEventListener('turbolinks:load', event => {
  const calendarButton = document.querySelector('.js-calendar')
  if (calendarButton === null) return

  calendarButton.addEventListener('click', () => {
    const itemsCalendar = document.querySelector('.js-items-calendar')
    if (itemsCalendar === null) return

    if (itemsCalendar.classList.contains('hidden')) {
      itemsCalendar.classList.remove('hidden')
    } else {
      itemsCalendar.classList.add('hidden')
    }
  })
})
