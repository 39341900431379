document.addEventListener('turbolinks:load', function () {
  let scrolled = true
  const descriptionGobPe = document.querySelector('.js-description-gob-pe')
  const breadcrumbHeader = document.querySelector('.js-breadcrumb-header')
  const escudo = document.querySelector('.js-escudo')

  if (!breadcrumbHeader || !escudo || !descriptionGobPe) return null
  window.addEventListener('scroll', () => {
    const doc = document.documentElement
    const scrollPos = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    if (scrollPos > 98 && scrolled === true) {
      if (breadcrumbHeader) breadcrumbHeader.classList.remove('hidden')
      if (descriptionGobPe) descriptionGobPe.classList.add('hidden')
      scrolled = false
    } else if (scrollPos < 98 && scrolled === false) {
      if (breadcrumbHeader) breadcrumbHeader.classList.add('hidden')
      if (descriptionGobPe) descriptionGobPe.classList.remove('hidden')
      scrolled = true
    }
  })
})
