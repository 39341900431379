document.addEventListener('turbolinks:load', async function () {
  const placeholder = 'No existen datos disponibles.'
  const dropdownUl = '.select_content'
  const dropdownListEl = 'li'
  const dropdownParent = document.querySelectorAll(dropdownUl)

  if (dropdownParent) {
    if (dropdownParent.length > 0) {
      for (let i = 0; i < dropdownParent.length; i++) {
        const dropdownList = []
        const dropdownElementParent = dropdownParent[i]
        const classElement = dropdownListEl + i
        const dropdownChild = dropdownElementParent.getElementsByTagName(dropdownListEl)

        dropdownElementParent.classList.add(classElement)

        for (let j = 0; j < dropdownChild.length; j++) {
          let value = j
          let markup = placeholder
          let elementChild = dropdownChild[j]
          let strong = elementChild.children[0] || ''

          if (strong) markup = elementChild.children[0].outerHTML

          const label = elementChild.innerText.replace(strong.innerText, '')

          dropdownList.push({ value, label, markup })
        }

        const render = (await import('../dynamic_render')).default
        const Select = (await import('./select')).default
        render(Select, { list: dropdownList }, document.querySelector('.' + classElement))
      }
    }
  }
})
