document.addEventListener('turbolinks:load', function () {
  document.querySelectorAll('.js-link-section').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault()
      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      })
    })
  })
})
