document.addEventListener('turbolinks:load', function () {
  document.querySelectorAll('.nav-item-page a').forEach(nav_item => nav_item.classList.remove('underline'))

  if ((!fetch) in window) return

  var pageContainer = document.querySelector('.page-container')
  if (!pageContainer) return

  var siteProtocol = window.location.protocol
  var links = pageContainer.querySelectorAll('a')

  var urls = Array.prototype.slice.call(links).reduce(function (acc, link) {
    if (!link.protocol.startsWith('http')) return acc
    acc[link.href] = link
    return acc
  }, {})

  Object.keys(urls).forEach(function (url) {
    var link = urls[url]
    if (siteProtocol === 'https:' && link.protocol === 'http:') return

    if (link.host === 'www.gob.pe' || link.host === 'staging.www.gob.pe' || link.host === 'localhost:3000') return

    fetch(url, { method: 'head', mode: 'no-cors' }).catch(function (err) {
      var selectors = 'a[href="' + url + '"], ' + 'a[href="' + url.substring(0, url.length - 1) + '"]'

      $(selectors).each(function () {
        $(this).off('click')
        $(this).click(function (event) {
          var clickCount = window.sessionStorage.getItem(url)
          if (clickCount) return

          event.preventDefault()
          window.sessionStorage.setItem(url, 1)
        })
      })

      var tooltip = new Tooltip(link, {
        placement: 'left',
        title: '¡Uy, hay un problema! Inténtalo luego.',
        trigger: 'hover focus'
      })
    })
  })
})
