import * as $ from 'jquery'
$(document).on('turbolinks:load', function () {
  window.setTimeout(function () {
    $('.twitter-timeline').contents().find('.timeline-tweet-text').css('font-size', '16px')
    // hack
    // $('.twitter-timeline').prop('tabindex', '-1')
  }, 2000)

  // window.setTimeout(function() {
  //   document.querySelector('._2p3a').style.width = '100%'
  // }, 20000)
})
