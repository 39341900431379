import * as $ from 'jquery'
$(document).on('turbolinks:load', function () {
  if ($('.page-for-comment-container').length > 0) {
    $('.comments-group').each(function () {
      var elemHeight = $('.internal').height(),
        userNotes = $('.icons-container').find('.user-note'),
        container = $('.notes-setHT')

      container.height(elemHeight)

      setTimeout(function () {
        if (userNotes.length) {
          userNotes.each(function () {
            var $elNote = $(this),
              $noteID = $elNote.data('rel'),
              $position = $('#' + $noteID).position()
            if ($position != undefined) $elNote.css({ top: $position.top, opacity: 1 })
          })
        }
      }, 500)
    })

    $(document).on('click', 'span.user-note-nocount', function () {
      hideControls()
      var $user_note = $(this).parent()
      active_new_note_nocount($user_note)
    })

    $(document).on('click', 'span.user-note-commented', function () {
      hideControls()
      var $user_note = $(this).parent()
      active_new_note_commented($user_note)
    })

    $(document).on('click', '.note-active span.user-note-commented', function () {
      hideControls()
    })

    var Document = $('.container .internal'),
      noteCont = $('.notes-container'),
      notesList = $('#notes-list'),
      noteEditor = $('#note-editor'),
      newNote = noteCont.find('.notes-new-note'),
      addNote = notesList.find('.addNote'),
      userNote = $('#user-notes').find('.user-note'),
      saveNote = $('#save-note'),
      cancelNote = $('#cancel-note'),
      selectedText = '',
      selectedPId = ''

    var msgError = $('#message-error')

    $(document).on('click', '#notes-list .addNote', function (e) {
      e.preventDefault()
      cancelEdition()
      if (noteEditor.text().trim() != '') noteEditor.html('<p>Ingresa un comentario</p>')
      msgError.hide()
      $(this).addClass('hide')
      newNote.css({
        top: $(this).parent().position().top + $(this).parent().height()
      })

      newNote.addClass('new-note-highlighted')
      setTimeout(function () {
        noteEditor.attr('data-reference', '')
      }, 500)
    })

    noteEditor.focusin(function () {
      if ($(this).text().trim() == 'Ingresa un comentario') $(this).text('')
    })

    cancelNote.on('click', function () {
      addNote = $('#notes-list .addNote')
      if (addNote.length > 0) {
        addNote.removeClass('hide')
      } else {
        userNote.removeClass('note-active')
      }

      newNote.removeClass('new-note-highlighted')
      newNote.removeClass('answer-nav')
      msgError.css({ display: 'none' })

      notesList.after(newNote)
      $('#notes-list li.empty').remove()
    })

    saveNote.on('click', function () {
      if (noteEditor.text().trim() == 'Ingresa un comentario' || noteEditor.text().trim() == '') {
        msgError.css({ display: 'block' })
        noteEditor.text('')
        noteEditor.focus()
      } else {
        var text = ''
        if (noteEditor.attr('data-type') == 1) text = $('#' + noteEditor.attr('data-rel') + ' mark').text()
        $.ajax({
          type: 'POST',
          url: '/admin/comments',
          data: {
            type: noteEditor.attr('data-type'),
            id: noteEditor.attr('data-id'),
            rel: noteEditor.attr('data-rel'),
            note: noteEditor.text().trim(),
            sel: text,
            reference: noteEditor.attr('data-reference')
          }
        })

        cancelNote.click()
      }
      clean_marks()
      return false
    })

    $(document).on('click', '.note-nav .edit-comment', function (e) {
      e.preventDefault()
      cancelEdition()
      cancelNote.click()
      var $this = $(this),
        noteNav = $this.closest('li.note-nav'),
        noteEditorNav = noteNav.find('.note-editor-nav')
      commentId = $this.data('commentId')
      noteNav.find('.options').hide()
      noteNav.find('.standard').hide()
      noteNav.find('.edition').show()
      noteEditorNav.attr('contenteditable', true)
      noteEditorNav.addClass('editable')
      setEndOfContenteditable(document.getElementById(noteEditorNav.attr('id')))
    })

    $(document).on('click', '.note-nav .delete-comment', function (e) {
      e.preventDefault()
      var $this = $(this),
        commentId = $this.data('commentId')
      $.ajax({
        type: 'DELETE',
        dataType: 'json',
        url: $this.attr('href'),
        succes: function (data) {
          $this.parent().parent().remove()
        }
      })
    })

    $(document).on('click', '.note-nav #cancel-edition-note', function (e) {
      cancelEdition()
    })

    $(document).on('click', '.note-nav #save-edition-note', function (e) {
      var $this = $(this),
        noteNav = $this.closest('li.note-nav'),
        noteEditor = noteNav.find('.note-editor-nav')
      commentId = $this.data('commentId')

      if (noteEditor.text().trim() == '') {
        alert('Debes ingresar un comentario')
        noteEditor.focus()
      } else {
        $.ajax({
          type: 'PUT',
          dataType: 'json',
          url: '/admin/comments/' + commentId,
          data: {
            page_id: noteEditor.attr('data-page-id'),
            note: noteEditor.text().trim()
          },
          succes: function (data) {
            cancelEdition()
          }
        })
      }
    })

    $(document).on('click', '#notes-list #answer-note', function (e) {
      cancelEdition()
      var $this = $(this),
        noteNav = notesList.find("li.note-nav[data-reference='" + $this.data('comment-id') + "']").last()
      $('#notes-list .addNote').click()
      noteNav.after("<li class='empty'></li>")
      newNote.addClass('answer-nav')
      newNote.removeAttr('style')
      noteNav.next().append(newNote)
      setTimeout(function () {
        noteEditor.attr('data-reference', $this.data('comment-id'))
        noteEditor.focus()
      }, 500)
    })

    $(document).on('click', '#notes-list #resolve-note', function (e) {
      var $this = $(this),
        commentId = $this.data('comment-id')
      cancelNote.click()
      setTimeout(function () {
        if (confirm("Este comentario será marcado como 'Resuelto'. ¿Estás seguro?")) {
          $.ajax({
            type: 'PUT',
            dataType: 'json',
            url: '/admin/comments/' + commentId + '/resolve',
            succes: function (data) {
              $('.note-nav').each(function () {
                if ($(this).attr('data-reference') === data.data_reference) {
                  $(this).remove()
                }
              })
            }
          })
        }
      }, 300)
    })

    Document.mousedown(function (e) {
      notesList.html('')
      hideControls()
    })

    Document.mouseup(function (e) {
      selectedText = String(getTextSelected()).trim()
      if (selectedText != '') {
        var target = e.target.id
        if (target == '') target = $(e.target).parent().attr('id')
        if (target != undefined) {
          //Set mark element
          replaceText($('#' + target), selectedText)

          var $span_note = $("#user-notes .user-note[data-rel='" + target + "'] span"),
            $user_note = $span_note.parent()

          if ($span_note.hasClass('user-note-nocount')) {
            active_new_note_nocount($user_note)
          }
          if ($span_note.hasClass('user-note-commented')) {
            active_new_note_commented($user_note)
            setTimeout(function () {
              $('#notes-list .addNote').click()
            }, 500)
          }
          setTimeout(function () {
            noteEditor.attr('data-type', 1)
            noteEditor.focus()
          }, 500)
        }
      }
    })

    // Show <MARK/> in Paragraph
    $(document)
      .on('mouseover', '#notes-list .note-nav', function () {
        if (noteEditor.attr('data-type') != '1')
          replaceText($('#' + $(this).attr('data-rel')), $(this).find('input').val())
      })
      .on('mouseout', '#notes-list .note-nav', function () {
        if (noteEditor.attr('data-type') != '1') clean_marks()
      })

    $(document).on('mouseover', '.container .internal p, .container .internal li', function () {
      var id = $(this).attr('id')
      userNote = $("#user-notes .user-note:not('.commented')[data-rel='" + id + "']")
      if (selectedPId != id) {
        selectedPId = id
        hideIcons()
        hideControls()
        userNote.css('display', 'block')
      }
    })
  }
})

function active_new_note_nocount($user_note) {
  $user_note.addClass('note-active')
  var $note_editor = $('#note-editor')
  $note_editor.attr('data-rel', $user_note.attr('data-rel'))
  $note_editor.attr('data-type', 0)
  if ($note_editor.text().trim() != '') $note_editor.html('<p>Ingresa un comentario</p>')
  $('#message-error').css({ display: 'none' })
  var $new_note = $('.notes-container .notes-new-note')
  $new_note.css({ top: $user_note.position().top })
  $new_note.addClass('new-note-highlighted')
  var $notes_list = $('#notes-list')
  $notes_list.html('')
  $notes_list.css({ top: $user_note.position().top })
  if ($note_editor.text().trim() == '') $note_editor.focus()
}

function active_new_note_commented($user_note) {
  $user_note.addClass('note-active')
  var $note_editor = $('#note-editor')
  $note_editor.attr('data-rel', $user_note.attr('data-rel'))
  $note_editor.attr('data-type', 0)
  var $notes_list = $('#notes-list')
  $notes_list.html('')
  $notes_list.css({ top: $user_note.position().top })
  $notes_list.addClass('new-note-highlighted')
  $.ajax({
    url: '/admin/comments',
    data: { id: $user_note.attr('data-id'), rel: $user_note.attr('data-rel') },
    error: function () {
      alert('Failed to connect to server.')
    }
  })
  return false
}

function replaceText($div, $text) {
  var src_str = $div.html()
  var term = $text
  if (term != undefined) {
    term = term.replace(/(\s+)/, '(<[^>]+>)*$1(<[^>]+>)*')
    var pattern = new RegExp('(' + term + ')', 'i')

    src_str = src_str.replace(pattern, '<mark>$1</mark>')
    src_str = src_str.replace(/(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/, '$1</mark>$2<mark>$4')

    $div.html(src_str)
  }
}

function clean_marks() {
  if ($('.container .internal').find('mark').length > 0)
    $('.container .internal mark').replaceWith($('.container .internal').find('mark').text())
}

function hideControls() {
  $('#notes-list').html('')
  $('#user-notes .user-note').removeClass('note-active')
  $('#notes-list').removeClass('new-note-highlighted')
  $('.notes-container .notes-new-note').removeClass('new-note-highlighted')
  clean_marks()
}

function hideIcons() {
  $("#user-notes .user-note:not('.commented')").hide()
}

function cancelEdition() {
  var noteEditorNav = $('.note-editor-nav')
  noteEditorNav.removeClass('editable')
  noteEditorNav.removeAttr('contenteditable')
  $('.note-nav .options').show()
  $('.note-nav .buttons .standard').show()
  $('.note-nav .buttons .edition').hide()
}

function getTextSelected() {
  var t = ''
  if (window.getSelection) {
    t = window.getSelection()
  } else if (document.getSelection) {
    t = document.getSelection()
  } else if (document.selection) {
    t = document.selection.createRange().text
  }
  return t
}

function setEndOfContenteditable(contentEditableElement) {
  var range, selection
  if (document.createRange) {
    //Firefox, Chrome, Opera, Safari, IE 9+
    range = document.createRange() //Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement) //Select the entire contents of the element with the range
    range.collapse(false) //collapse the range to the end point. false means collapse to end rather than the start
    selection = window.getSelection() //get the selection object (allows you to change selection)
    selection.removeAllRanges() //remove any selections already made
    selection.addRange(range) //make the range you have just created the visible selection
  } else if (document.selection) {
    //IE 8 and lower
    range = document.body.createTextRange() //Create a range (a range is a like the selection but invisible)
    range.moveToElementText(contentEditableElement) //Select the entire contents of the element with the range
    range.collapse(false) //collapse the range to the end point. false means collapse to end rather than the start
    range.select() //Select the range (make it the visible selection
  }
}
