document.addEventListener('turbolinks:load', () => {
  const seeMoreLessElements = document.querySelectorAll('.js-cards-see-more-less')
  seeMoreLessElements.forEach((seeMoreLess, i) => {
    const seeMore = seeMoreLess.querySelector('.js-cards-see-more')
    if (seeMore) {
      seeMore.addEventListener('click', e => {
        const cardsExtrasElements = seeMoreLess.querySelectorAll('.js-cards-extras')
        cardsExtrasElements.forEach((cardsExtras, i) => {
          if (cardsExtras.classList.contains('md:hidden')) {
            cardsExtras.classList.remove('md:hidden')
            seeMore.querySelector('span.see-more').classList.add('hidden')
            seeMore.querySelector('span.see-less').classList.remove('hidden')
            seeMore.querySelector('.js-cards-up').classList.remove('hidden')
            seeMore.querySelector('.js-cards-down').classList.add('hidden')
          } else {
            cardsExtras.classList.add('md:hidden')
            seeMore.querySelector('span.see-more').classList.remove('hidden')
            seeMore.querySelector('span.see-less').classList.add('hidden')
            seeMore.querySelector('.js-cards-up').classList.add('hidden')
            seeMore.querySelector('.js-cards-down').classList.remove('hidden')
          }
        })
      })
    }
  })
})
