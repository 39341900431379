const Tabby = require('tabbyjs')

document.addEventListener('turbolinks:load', () => {
  const otherTabs = document.querySelectorAll('.js-tabs')
  const Segments = document.querySelectorAll('.js-tabs a')
  Segments.forEach(Segment => {
    Segment.removeAttribute('target')
    Segment.classList.remove('underline')
  })
  Array.from(otherTabs).forEach((tab, i) => {
    tab.setAttribute(`data-tabs-${i}`, '')
    new Tabby(`[data-tabs-${i}]`)
  })
})
