document.addEventListener('turbolinks:load', function () {
  var selector = null
  var changer = null
  document.querySelectorAll('.js-uit-calculator').forEach(el => {
    const uitValue = Number(el.dataset.uit)
    const unit = el.querySelector('.js-unit')
    const currency = el.querySelector('.js-currency')
    const percent = el.querySelector('.js-percent')
    const buttonUit = document.getElementById('js-botonuit')

    unit.addEventListener('keyup', event => {
      const changer = event.target.value
      currency.value = decimalIfNeeded(uitValue * changer)
      percent.value = decimalIfNeeded(100 * changer)
    })
    unit.addEventListener('focusout', event => {
      changer = event.target.value
      selector = 'unit'
    })

    currency.addEventListener('keyup', event => {
      const changer = event.target.value
      unit.value = decimalIfNeeded(changer / uitValue)
      percent.value = decimalIfNeeded((100 * changer) / uitValue)
    })
    currency.addEventListener('focusout', event => {
      changer = event.target.value
      selector = 'currency'
    })

    percent.addEventListener('keyup', event => {
      const changer = event.target.value
      unit.value = decimalIfNeeded(changer / 100)
      currency.value = decimalIfNeeded((uitValue * changer) / 100)
    })

    percent.addEventListener('focusout', event => {
      changer = event.target.value
      selector = 'percent'
    })

    buttonUit.addEventListener('click', event => {
      if (changer == null) changer = unit.value
      switch (selector) {
        case 'unit':
          currency.value = decimalIfNeeded(uitValue * changer)
          percent.value = decimalIfNeeded(100 * changer)
          break
        case 'currency':
          unit.value = decimalIfNeeded(changer / uitValue)
          percent.value = decimalIfNeeded((100 * changer) / uitValue)
          break
        case 'percent':
          unit.value = decimalIfNeeded(changer / 100)
          currency.value = decimalIfNeeded((uitValue * changer) / 100)
          break
        default:
          currency.value = decimalIfNeeded(uitValue * changer)
          percent.value = decimalIfNeeded(100 * changer)
      }
    })
  })

  const decimalIfNeeded = number => {
    return Number(number.toFixed(2))
  }
})
