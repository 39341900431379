const keys = {
  esc: 27,
  up: 38,
  down: 40
}
const ClickyMenus = function (menu) {
  let container = menu.parentElement,
    currentMenuItem,
    i,
    len

  this.init = function () {
    menuSetup()
    document.addEventListener('click', closeOpenMenu)
  }

  function toggleOnMenuClick(e) {
    const button = e.currentTarget
    if (currentMenuItem && button !== currentMenuItem) {
      toggleSubmenu(currentMenuItem)
    }
    toggleSubmenu(button)
  }

  function toggleOnMenuKeyDown(event) {
    let prevdef = false
    const button = event.currentTarget
    const isMenuOpen = 'true' === button.getAttribute('aria-expanded')
    switch (event.keyCode) {
      case keys.down:
        if (!isMenuOpen) toggleSubmenu(button)
        prevdef = true
        break
      case keys.up:
        if (isMenuOpen) toggleSubmenu(button)
        prevdef = true
        break
    }
    if (prevdef) {
      event.preventDefault()
      event.stopPropagation()
    }
    return false
  }

  function toggleSubmenu(button) {
    const submenu = document.getElementById(button.getAttribute('aria-controls'))
    if ('true' === button.getAttribute('aria-expanded')) {
      currentMenuItem = closeSubmenu(button)
    } else {
      button.setAttribute('aria-expanded', true)
      submenu.setAttribute('aria-hidden', false)
      currentMenuItem = button
      if (document.body.classList.contains('overlay-menu')) {
        toggleOverlay(true)
      }
    }
  }

  function closeSubmenu(button) {
    const submenu = document.getElementById(button.getAttribute('aria-controls'))
    button.setAttribute('aria-expanded', false)
    submenu.setAttribute('aria-hidden', true)
    if (document.body.classList.contains('overlay-menu')) {
      toggleOverlay(false)
    }
    return false
  }

  function closeOnEscKey(e) {
    if (keys.esc === e.keyCode) {
      if (null !== e.target.closest('ul[aria-hidden="false"]')) {
        currentMenuItem.focus()
        toggleSubmenu(currentMenuItem)
      } else if ('true' === e.target.getAttribute('aria-expanded')) {
        toggleSubmenu(currentMenuItem)
      }
    }
  }

  function closeOpenMenu(e) {
    if (currentMenuItem && !e.target.closest('#' + container.id)) {
      toggleSubmenu(currentMenuItem)
    }
  }

  function menuSetup() {
    menu.classList.remove('no-js')
    menu.querySelectorAll('ul').forEach(submenu => {
      const menuItem = submenu.parentElement
      if ('undefined' !== typeof submenu) {
        let button = convertLinkToButton(menuItem)
        setUpAria(submenu, button)
        button.addEventListener('click', toggleOnMenuClick)
        if (menuItem.classList.contains('js-has-submenu-home')) {
          menuItem.addEventListener('mouseenter', () => toggleOnMenuClick({ currentTarget: button }))
          menuItem.addEventListener('mouseleave', () => closeSubmenu(button))
        }

        button.addEventListener('keydown', toggleOnMenuKeyDown)
        menu.addEventListener('keyup', closeOnEscKey)
      }
    })
  }

  function convertLinkToButton(menuItem) {
    const link = menuItem.firstElementChild

    if (link && link.tagName.toLowerCase() === 'button') return link

    if (link && link.tagName.toLowerCase() !== 'a') return null

    const linkHTML = link.innerHTML,
      linkAtts = link.attributes,
      button = document.createElement('button')

    if (null !== link) {
      button.innerHTML = linkHTML.trim()
      for (i = 0, len = linkAtts.length; i < len; i++) {
        let attr = linkAtts[i]
        if ('href' !== attr.name) {
          button.setAttribute(attr.name, attr.value)
        }
      }
      menuItem.replaceChild(button, link)
    }
    return button
  }

  function setUpAria(submenu, button) {
    const submenuId = submenu.getAttribute('id')

    let id
    if (null === submenuId) {
      id = button.textContent.trim().replace(/\s+/g, '-').toLowerCase() + '-submenu'
    } else {
      id = submenuId.replace(/-submenu$/, '') + '-submenu'
    }

    button.setAttribute('aria-controls', id)
    button.setAttribute('aria-expanded', false)

    submenu.setAttribute('id', id)
    submenu.setAttribute('aria-hidden', true)
  }

  function toggleOverlay(show) {
    const overlay = document.getElementById('overlay-menu')
    overlay.classList.toggle('hidden', !show)
    overlay.classList.toggle('opacity-50', show)
    overlay.classList.toggle('opacity-0', !show)
  }
}

document.addEventListener('turbolinks:load', function () {
  const menus = document.querySelectorAll('.clicky-menu')
  menus.forEach(menu => {
    let clickyMenu = new ClickyMenus(menu)
    clickyMenu.init()
  })
})
