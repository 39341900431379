document.addEventListener('turbolinks:load', event => {
  chatWithUs()
})

const chatWithUs = () => {
  const chatWithUs = document.querySelector('.chat-with-us')
  if (chatWithUs === null) return
  const institution = JSON.parse(chatWithUs.dataset.institution)
  if (institution.checkChatUrl) {
    chatWithUs.classList.remove('hidden')
    chatWithUs.querySelector('.content').textContent = chatContent(institution)
    setInterval(verifyState, 0 / 60000, institution, chatWithUs)
  }
}

const verifyState = (institution, chatWithUs) => {
  if (chatEnable(institution)) {
    chatWithUs.classList.remove('disabled')
    chatWithUs.querySelector('.ico_happy').classList.remove('hidden')
    chatWithUs.querySelector('.ico_happy_gray').classList.add('hidden')
  } else {
    chatWithUs.classList.add('disabled')
    chatWithUs.removeAttribute('href')
    chatWithUs.querySelector('.ico_happy').classList.add('hidden')
    chatWithUs.querySelector('.ico_happy_gray').classList.remove('hidden')
  }
}

const chatEnable = institution => {
  return institution.chat_24_7 || chatBusinessHourCurrent(institution.chatBusinessHours)
}

const chatContent = institution => {
  let content = ''
  let index = -1
  if (institution.chat_24_7) {
    content = 'Disponible todos los días las 24 horas'
  } else {
    const textChatBusinessHours = []
    institution.chatBusinessHours.forEach(chatBusinessHour => {
      index = textChatBusinessHours.findIndex(x => x.includes(chatBusinessHour.days_name))
      if (index >= 0) {
        textChatBusinessHours[index] =
          `${textChatBusinessHours[index]} y de ${chatBusinessHour.start_time_text} a ${chatBusinessHour.end_time_text}`
      } else {
        textChatBusinessHours.push(
          `${chatBusinessHour.days_name} de ${chatBusinessHour.start_time_text} a ${chatBusinessHour.end_time_text}`
        )
      }
    })
    content = `Disponible ${textChatBusinessHours.join(' y ')}`
  }
  return content
}

const chatBusinessHourCurrent = chatBusinessHours => {
  const now = new Date()
  for (const chatBusinessHour of chatBusinessHours) {
    let regex = new RegExp(chatBusinessHour.days)
    if (
      regex.test(now.getDay()) &&
      checkIfTimeIsInRange(now, new Date(chatBusinessHour.start_time), new Date(chatBusinessHour.end_time))
    ) {
      return chatBusinessHour
    }
  }
  return null
}

const checkIfTimeIsInRange = (d, start, end) => {
  return start.getHours() < d.getHours() && d.getHours() < end.getHours()
    ? true
    : start.getHours() == d.getHours()
      ? d.getHours() == end.getHours()
        ? start.getMinutes() <= d.getMinutes() && d.getMinutes() <= end.getMinutes()
        : start.getMinutes() <= d.getMinutes()
      : d.getHours() == end.getHours()
        ? d.getMinutes() <= end.getMinutes()
        : false
}
