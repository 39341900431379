// TODO: this function is working?? is global?
import * as $ from 'jquery'
import debounce from 'lodash/debounce'

var debounced = debounce(function (form) {
  $(form).closest('form').submit()
}, 200)

const debouncedFive = debounce(
  form => {
    form.closest('form').submit()
  },
  500,
  true
)

$(document).on('keyup', '.submit_on_keyup', function (event) {
  if ((event.keyCode >= 48 && event.keyCode <= 90) || event.keyCode == 229 || event.keyCode == 8) {
    debounced(this)
  }
})

$(document).on('click', '.js-shown-on-click-negative', function (event) {
  event.preventDefault()
  $('.js-shown-on-click-positive').find('.ball').removeClass('active')
  var target = $(this).data('target')
  $(this).find('.ball').addClass('active')
  $(this).attr('aria-expanded', true)
  $(target).show()
  $('html, body').animate(
    {
      scrollTop: $(this).offset().top
    },
    250
  )
})

$(document).on('click', '.js-shown-on-click-positive', function (event) {
  $(this).find('.ball').addClass('active')
})

$(document).on('click', '.contact-form-trigger', function (event) {
  $(this).find('i').toggleClass('icon-up').toggleClass('icon-down')
  $('.page-contact-form').toggle()
})

$(document).on('click', '.show-contact', function (event) {
  var target = $(this).data('target')
  if ($(target).is(':visible')) $(target).hide()
  else {
    $(target).show()
    $(target).find('input').focus()
  }
})

// esto ya no se utiliza para el buscador de Officials (funcionarios), pero aun se usa en documents_collections (se deberia unificar para dejar de usarse)
document.addEventListener('turbolinks:load', () => {
  const forms = document.querySelectorAll('.js-form-search-official')
  const searchInput = document.querySelector('.submit_on_change')
  const searchInputElement = document.querySelector('.submit_on_change_all_keys')
  Array.from(forms).forEach(form => {
    form.addEventListener('keypress', event => {
      const keyPressed = event.keyCode || event.which
      if (keyPressed === 13) {
        event.preventDefault()
        return false
      }
    })
  })
  if (searchInput != null) {
    searchInput.addEventListener('keyup', event => {
      if ((event.keyCode >= 48 && event.keyCode <= 90) || event.keyCode == 229 || event.keyCode == 8) {
        debouncedFive(searchInput)
      }
    })
  }
  if (searchInputElement) {
    searchInputElement.addEventListener('input', event => {
      debouncedFive(searchInputElement)
    })
  }
})
