import * as $ from 'jquery'
document.addEventListener('turbolinks:load', () => {
  $('.js-read-more-link').click(function (e) {
    e.preventDefault()
    var target = $('#more_' + $(this).data('target'))

    if ($('#see_more_' + $(this).data('target')).length) {
      var seeMore = $('#see_more_' + $(this).data('target'))
    } else {
      var seeMore = $('.js-read-more-link')
    }

    if (target.css('display') == 'none') {
      target.show()
      seeMore.find('i').toggleClass('icon-down icon-up')
      seeMore.find('span').text('Ver menos')
    } else {
      target.hide()
      seeMore.find('i').toggleClass('icon-up icon-down')
      seeMore.find('span').text('Ver más')
    }
  })
})
